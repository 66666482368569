import React, { Component } from 'react';
import './ProjectDetails.scss';
import Header from "../../components/header/Header";
import Sidebar from "../../components/sidebar/Sidebar";
import Preloader from "../../components/preloader/Preloader";
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from "prop-types";
import axios from 'axios';
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem } from 'reactstrap';
import Moment from 'react-moment';
import AddProject from "../../components/addProject/AddProject";
import AddParent from "../../components/addParent/AddParent";
import CostEstimates from "../../components/costEstimates/CostEstimates";
import { toast } from 'react-toastify';
import confirm from 'reactstrap-confirm';
import {withRouter} from 'react-router';
import { compose } from 'recompose';
import ProjectResources from '../../components/projectResources/ProjectResources';
import TimesheetEntries from '../../components/timesheetEntries/TimesheetEntries';
import PurchaseOrders from '../../components/purchaseOrders/PurchaseOrders';
import ExternalExpenses from '../../components/externalExpenses/ExternalExpenses';
import { createAction } from '../../utils/utils';
import AppContext from "../../context/index";

class ProjectDetails extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);
        const { match : {params}} = this.props;

        this.state = {
            loaded: false,
            projectId: params.projectId,
            isLimitedPm: 0,
            projectData: {},
            costEstimates: [],
            purchaseOrders: [],
            costEstimatesItems: [],
            estimatedTotalCost: 0,
            developmentTotalCost: 0,
            externalTotalCost: 0,
            showAddResource: false,
            showAddParent: false,
            userRole: -1,
            posNumber: 0,
            expNumber: 0,
            resources: []
        }
    }

    componentDidMount(){
        const { cookies } = this.props;
        const { match : {params}} = this.props;

        //role = 2 for superadmin or idg pm and 1 for devs
        this.setState({
            userRole: cookies.get('userRole'),
            isLimitedPm: parseInt(cookies.get('isLimitedPm'))
        })

        this.getProjectDetails(params.projectId);
        createAction(1, 'projects', params.projectId, cookies.get('authToken'));
    }

    /* PROJECT DETAILS */
    getProjectDetails = (projectId) => {
        const { cookies } = this.props;

        this.setState({
            loading: true
        });

        axios.get(process.env.REACT_APP_API_URL + 'project/' + projectId, {
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            res = res.data.data;
            console.log({res})

            this.setState({
                projectData: res
            });

            if(!res.has_access){
                window.location = '/projects';
            }

            if (this.state.userRole == 2) {
                this.setState({
                    developmentTotalCost: res.timesheet_entries.reduce((ac, item) => ac + Number(item.cost), 0),
                    externalTotalCost: res.external_expenses.reduce((ac, item) => ac + Number(item.value), 0),
                    costEstimates: res.cost_estimates,
                    purchaseOrders: res.cost_estimates.map(({pos}) => pos).flat(),
                    costEstimatesItems: res.cost_estimate_items,
                    estimatedTotalCost: res.cost_estimates.reduce((a, b) => Number(a) + (b.status_id == 1 ? Number(b.value) : 0), 0),
                });
            }

            this.setState({
                loaded: true,
                loading: false
            });
        })
        .catch(err => {
            if(typeof err.response != "undefined" && err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    }

    getCostEstimates = () => {
        const { cookies } = this.props;

        this.setState({
            loading: true
        });

        axios.get(process.env.REACT_APP_API_URL + 'cost-estimates/' + this.state.projectId, {
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
          .then(res => {
              res = res.data.data;
              this.setState({
                  costEstimates: res.cost_estimates,
                  costEstimatesItems: res.cost_estimate_items,
                  estimatedTotalCost: res.cost_estimates.reduce((a, b) => Number(a) + (b.status_id == 1 ? Number(b.value) : 0), 0),
                  loading: false
              });
          })
          .catch(err => {
              if(typeof err.response !== "undefined" && err.response.status === 401){
                  //redirect to login
                  this.context.redirectToLogin();
              } else {
                  this.setState({
                      loading: false
                  });
                  console.err(err);
              }
          })
    }

    /* CLOSE PROJECT */
    toggleProject = (projectId, statusId) => {
        const { cookies } = this.props;

        let formData = new FormData();
        formData.append('status_id', statusId);

        axios({
            method: "POST",
            url: process.env.REACT_APP_API_URL + 'update-projects-status/' + projectId,
            data: formData,
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken'),
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(res => {
            this.setState({
                loading: false,
            })
            toast.success("Project's status been changed.");
            this.getProjectDetails(projectId);
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                })
            }
        })
    }

    openEditModal = () => {
        this.setState({
            showAddProject: true
        });
    }

    closeEditModal = refreshList => {
        this.setState({
            showAddProject: false
        });
        if(refreshList) {
            const { match : {params}} = this.props;
            this.getProjectDetails(params.projectId);
        }
    }

    openParentModal = () => {
        this.setState({
            showAddParent: true
        });
    }
    closeParentModal = refreshProject => {
        this.setState({
            showAddParent: false
        });
        if(refreshProject) {
            const { match : {params}} = this.props;
            this.getProjectDetails(params.projectId);
        }
    }

    removeParent = async () => {
        const { cookies } = this.props;

        let result = await confirm({
            title: 'Are you sure?',
            message: "You are about to remove the parent of this project",
            confirmText: "DELETE",
            confirmColor: "primary",
            cancelColor: "text-danger"
        });

        if (result) {
            axios({
                method: "DELETE",
                url: process.env.REACT_APP_API_URL + 'project-parents/' + this.state.projectData.id,
                headers: {
                    'Authorization': 'Bearer ' + cookies.get('authToken')
                }
            })
            .then(res => {
                this.getProjectDetails(this.state.projectData.id);
                toast.success("Project's parent has been removed.");
            })
        }
    };

    removeChild = async (subprojectId) => {
        const { cookies } = this.props;

        let result = await confirm({
            title: 'Are you sure?',
            message: "You are about to remove a child of this project",
            confirmText: "DELETE",
            confirmColor: "primary",
            cancelColor: "text-danger"
        });

        if (result) {
            axios({
                method: "DELETE",
                url: process.env.REACT_APP_API_URL + 'project-child/' + this.state.projectData.id + '/' + subprojectId,
                headers: {
                    'Authorization': 'Bearer ' + cookies.get('authToken')
                }
            })
            .then(res => {
                this.getProjectDetails(this.state.projectData.id);
                toast.success("Subproject has been removed.");
            })
        }
    };

    syncPO = () => {
        let auxPosNumber = this.state.posNumber;
        this.setState({
            posNumber: auxPosNumber + 1
        });
        this.getCostEstimates();
    };

    syncResources = (resources) => {
        this.setState({
            resources: resources.filter((obj, pos, arr) => {
                return arr.map(mapObj => mapObj.user_id).indexOf(obj.user_id) === pos
            })
        });
    };

    syncExternalExpenses = (externalExpenses) => {
        let auxExpNumber = this.state.expNumber;

        this.setState(prevState => ({
            projectData: {
                ...prevState.projectData,
                external_expenses: externalExpenses
            },
            externalTotalCost: externalExpenses.reduce((a, b) => Number(a) + Number(b.value), 0),
            expNumber: auxExpNumber + 1
        }))
    };

    render() {
        let startDate = new Date(this.state.projectData.start_date);
        let deadline = new Date(this.state.projectData.deadline);
        let today = new Date();

        let totalDaysDiff = Math.abs(deadline.getTime() - startDate.getTime());
        let remainingDaysDiff = Math.abs(today.getTime() - startDate.getTime());
        let totalDays = Math.ceil(totalDaysDiff / (1000 * 3600 * 24));
        let remainingDays = Math.ceil(remainingDaysDiff / (1000 * 3600 * 24));
        let remPercent = remainingDays / totalDays;
        let widthPercent = remPercent > 1 ? '100%' : remPercent * 100 + '%';
        let statusColor = remPercent < 0.5 ? 'green' : remPercent < 0.75 ? 'orange': 'red';

        return (
            <div className="page--with-header-sidebar">
                { this.state.loading && <Preloader/> }
                <Sidebar/>
                <div className="page--header-and-content">
                    {this.state.projectData.title &&
                    <Header
                        pageTitle={this.state.projectData.title}
                        headerOptions={[
                            {
                                name: 'Edit project',
                                action: 'openEditModal',
                                params: true,
                                iconClass: 'la la-pencil'
                            }
                        ]}
                        roleNeeded={2}
                        openEditModal={this.openEditModal}
                    />
                    }
                    <div className="ProjectDetailsPage">
                        <div className="row project-details-row">
                            <div className={this.state.userRole == 2 ? 'col-md-6' : 'col'}>
                                <div className="project-subsection">
                                    <div className="row project-subsection--head">
                                        <div className="col-10"><h1>Project summary</h1></div>
                                        <div className="col-2 ta-right">
                                            {this.state.userRole == 2 &&
                                                <UncontrolledDropdown>
                                                    <DropdownToggle nav>
                                                        <i className="la la-plus-la la-ellipsis-h"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu right>
                                                        <DropdownItem key={'ps-1'} onClick={this.openEditModal}>Edit this project</DropdownItem>

                                                        {!this.state.projectData.parent_id && !this.state.projectData.has_children ?
                                                        <DropdownItem key={'ps-2'} onClick={this.openParentModal}>Set parent for this project</DropdownItem>
                                                        : this.state.projectData.parent_id ?
                                                        <DropdownItem key={'ps-2'} onClick={this.removeParent}>Remove parent</DropdownItem>
                                                        : ''
                                                        }

                                                        {this.state.projectData.status_id != 5 ?
                                                        <DropdownItem key={'ps-5'} onClick={() => this.toggleProject(this.state.projectData.id, 5)}>Cancel this project</DropdownItem>
                                                          : ''
                                                        }

                                                        {this.state.projectData.status_id != 3 && this.state.projectData.status_id != 5 ?
                                                        <DropdownItem key={'ps-3'} onClick={() => this.toggleProject(this.state.projectData.id, 3)}>Close this project</DropdownItem>
                                                        :
                                                        <DropdownItem key={'ps-3'} onClick={() => this.toggleProject(this.state.projectData.id, 2)}>Open this project</DropdownItem>
                                                        }
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            }
                                        </div>
                                        <i className={'project-completion ' + statusColor} style={{width: widthPercent}}></i>

                                        {this.state.showAddProject &&
                                            <AddProject
                                                projectId={this.state.projectData.id}
                                                closeEditModal={this.closeEditModal}
                                                showPopup={this.state.showAddProject}
                                            />
                                        }
                                        {this.state.showAddParent &&
                                            <AddParent
                                                projectId={this.state.projectData.id}
                                                closeModal={this.closeParentModal}
                                                showPopup={this.state.showAddParent}
                                            />
                                        }
                                    </div>

                                    <div className="project-subsection--body">
                                        <div className="proj-details">
                                            <span className={"proj-details--value " + ((this.state.projectData.status_id == 3 || this.state.projectData.status_id == 5) ? 'danger' : (this.state.projectData.status_id == 1 ? 'warning' : 'success'))}>
                                                <span className="col--value">{this.state.projectData.project_status}</span>
                                            </span>
                                        </div>
                                        <div className="proj-details">
                                            <p className="proj-details--label">Job Numbers</p>
                                            <p className="proj-details--value">{this.state.projectData.job_numbers ? this.state.projectData.job_numbers.map((item) => { return item.name }).join(',') : ''}</p>
                                        </div>
                                        <div className="proj-details">
                                            <div className="row">
                                                <div className="col-6">
                                                    <p className="proj-details--label">Title</p>
                                                    <p className="proj-details--value">{this.state.projectData.title}</p>
                                                </div>
                                                <div className="col-6">
                                                    <p className="proj-details--label">Business Units</p>
                                                    <p className="proj-details--value">{this.state.projectData.business_units}</p>
                                                </div>
                                            </div>
                                        </div>
                                        {this.state.projectData.description &&
                                        <div className="proj-details">
                                            <p className="proj-details--label">Description</p>
                                            <p className="proj-details--value">{this.state.projectData.description}</p>
                                        </div>
                                        }

                                        <div className="proj-details">
                                            <div className="row">
                                                <div className="col-6">
                                                    <p className="proj-details--label">Brand</p>
                                                    <p className="proj-details--value">{this.state.projectData.brand_name}</p>
                                                </div>
                                                <div className="col-6">
                                                    <p className="proj-details--label">Client</p>
                                                    <p className="proj-details--value">{this.state.projectData.client_name}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="proj-details">
                                            <div className="row">
                                                {this.state.projectData.hg_pm_name &&
                                                <div className="col-6">
                                                    <p className="proj-details--label">Client PM</p>
                                                    <p className="proj-details--value">{this.state.projectData.hg_pm_name}</p>
                                                </div>
                                                }
                                                <div className="col-6">
                                                    <p className="proj-details--label">IDG PM</p>
                                                    <p className="proj-details--value">{this.state.projectData.idg_pm_name}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="proj-details">
                                            <div className="row">
                                                <div className="col-6">
                                                    <p className="proj-details--label">Owner</p>
                                                    <p className="proj-details--value">{this.state.projectData.owner_name}</p>
                                                </div>
                                                <div className="col-6">
                                                    <p className="proj-details--label">Category</p>
                                                    <p className="proj-details--value">{this.state.projectData.category_title}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="proj-details">
                                            <div className="row">
                                                <div className="col-6">
                                                    <p className="proj-details--label">Team leads</p>
                                                    {/* show only name from teamlead_ids json */}
                                                    <p className="proj-details--value">
                                                        {this.state.projectData.teamlead_ids && JSON.parse(this.state.projectData.teamlead_ids).length > 0 && JSON.parse(this.state.projectData.teamlead_ids).map((teamlead, index) => {
                                                            return <span key={index}>{teamlead.name}{index < JSON.parse(this.state.projectData.teamlead_ids).length-1 ? ', ' : ''}</span>
                                                        })}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="proj-details">
                                            <div className="row">
                                                <div className="col-6">
                                                    <p className="proj-details--label">Start date</p>
                                                    <p className="proj-details--value"><Moment format={'DD.MM.YYYY'}>{new Date(this.state.projectData.start_date)}</Moment></p>
                                                </div>
                                                <div className="col-6">
                                                    <p className="proj-details--label">Deadline</p>
                                                    <p className="proj-details--value"><Moment format={'DD.MM.YYYY'}>{new Date(this.state.projectData.deadline)}</Moment></p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="proj-details">
                                            <p className="proj-details--label">Type</p>
                                            <p className="proj-details--value">{this.state.projectData.type_name}</p>
                                        </div>

                                        {this.state.projectData.settle_title && (this.state.userRole == 2) &&
                                        <div className="proj-details">
                                            <p className="proj-details--label">Settle</p>
                                            <p className="proj-details--value"><a href={'/project/' + this.state.projectData.parent_id}>{this.state.projectData.settle_title}</a></p>
                                        </div>
                                        }

                                        {this.state.projectData.children &&
                                        <div className="proj-details">
                                            <p className="proj-details--label">Subprojects</p>
                                            <ul className="subprojects--list">
                                                {this.state.projectData.children.map((subproject, index) => {
                                                    return(
                                                        <li key={'sub-' + index}>
                                                            {this.state.userRole == 2 &&
                                                                <button onClick={() => this.removeChild(subproject.id)}><i className="la la-trash-o"></i></button>
                                                            }
                                                            <a href={'/project/' + subproject.id}>{subproject.title}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* if userRole is 2 or userId is in teamlead_ids */}
                            {(this.state.userRole == 2 || this.state.projectData.teamlead_view) &&
                                <div className="col-md-6">
                                    {this.state.projectData.id &&
                                        <ProjectResources
                                            projectId={this.state.projectData.id}
                                            syncResources={this.syncResources}
                                            noControls={this.state.projectData.teamlead_view}
                                        />
                                    }
                                </div>
                            }
                        </div>

                        {this.state.userRole == 2 && !this.state.isLimitedPm && Object.keys(this.state.projectData).length > 0 &&
                        <div className="project-details-row">
                            <div className="project-subsection">
                                <div className="row project-subsection--head">
                                    <div className="col-10">
                                        <h1>Financial Summary
                                        <strong> • <small>Profit:</small> {Number(this.state.estimatedTotalCost - this.state.developmentTotalCost - this.state.externalTotalCost)} </strong> <span className="small pl-1">(A - B)</span>
                                        </h1>
                                    </div>
                                </div>
                                <div className="project-subsection--body">
                                <section className="proj-details">
                                    <div className="proj-details--value">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="proj-details--label pb-1 row">
                                                    {!this.state.isLimitedPm &&
                                                        <div className="col-6 pr-0 pl-0"><strong>A. Estimate
                                                            Cost: {this.state.estimatedTotalCost}</strong></div>
                                                    }
                                                    <div className="col-2 pl-0">Qty</div>
                                                    <div className="col-3 pr-0">Price/Unit</div>
                                                </div>
                                                {this.state.costEstimatesItems.map((item, index) =>
                                                  (
                                                    <div className="small row" key={'estimate-items-' + index}>
                                                        <div className="col-6 col--label pr-0">{item.activity_name} <span className="green small">{item.status_id == 1 ? ' ✅ ': (item.status_id == 2 ? ' ❌ ': ' ⌛ ')}</span></div>
                                                        <div className="col-2 pl-0">{item.quantity}h</div>
                                                        <div className="col-3 pr-0">{item.unit_cost}</div>
                                                    </div>
                                                  )
                                                )}
                                            </div>
                                            <div className="col-6">
                                                <div className="proj-details--label pb-1 row">
                                                    <div className="col-12 pl-0 pb-2"><strong>B. Actual Cost: {this.state.developmentTotalCost + this.state.externalTotalCost}</strong> (C + D)</div>
                                                </div>
                                                <div className="proj-details--label pb-1 row">
                                                    <div className="col-6 pr-0 pl-0"><strong>C. Development Cost: {this.state.developmentTotalCost}</strong></div>
                                                    {!this.state.isLimitedPm &&
                                                        <div className="col-2 pl-0">Qty</div>
                                                    }
                                                    <div className="col-3 pr-0">Price/Unit</div>
                                                </div>
                                                {this.state.projectData.timesheet_entries.map((item, index) =>
                                                  (
                                                    <div className="small row" key={'timesheet-items-' + index}>
                                                        <div className="col-6 col--label pr-0">{item.activity_name}</div>
                                                        <div className="col-2 pl-0">{item.quantities}h</div>
                                                        <div className="col-3 pr-0">{item.unit_cost}</div>
                                                    </div>
                                                  )
                                                )}

                                                <div>
                                                    <div className="proj-details--label pt-3 pb-1 row">
                                                        <div className="col-6 col--label pr-0 pl-0"><strong>D. External Cost: {this.state.externalTotalCost}</strong></div>
                                                        <div className="col-2 pl-0">Currency</div>
                                                        <div className="col-3 pr-0">Value</div>
                                                    </div>
                                                    {this.state.externalTotalCost > 0 && this.state.projectData.external_expenses.map((item, index) =>
                                                      (
                                                        <div className="small row" key={'external-items-' + index}>
                                                            <div className="col-6 col--label pr-0">{item.description}</div>
                                                            <div className="col-2 pl-0">{item.currency}</div>
                                                            <div className="col-4 pr-0">{item.value}</div>
                                                        </div>
                                                      )
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                </div>
                            </div>
                          </div>
                        }

                        {this.state.userRole == 2 && !this.state.isLimitedPm && this.state.loaded &&
                            <div className="project-details-row">
                                {this.state.projectData.id &&
                                    <CostEstimates
                                        getCostEstimates={this.getCostEstimates}
                                        costEstimatesItems={this.state.costEstimatesItems}
                                        costEstimates={this.state.costEstimates}
                                        estimatedTotalCost={this.state.estimatedTotalCost}
                                        projectId={this.state.projectData.id}
                                        projectParentId={this.state.projectData.parent_id}
                                        posNumber={this.state.posNumber}
                                    />
                                }
                            </div>
                        }

                        {this.state.userRole == 2 && !this.state.isLimitedPm && this.state.loaded &&
                            <div className="project-details-row">
                                {this.state.projectData.id && !this.state.projectData.parent_id &&
                                    <PurchaseOrders
                                        projectId={this.state.projectData.id}
                                        costEstimates={this.state.costEstimates}
                                        purchaseOrders={this.state.purchaseOrders}
                                        syncPO={this.syncPO}
                                    />
                                }
                            </div>
                        }

                        {this.state.userRole == 2 && !this.state.isLimitedPm &&
                        <div className="project-details-row">
                            {this.state.projectData.id && !this.state.projectData.parent_id &&
                            <ExternalExpenses
                              externalExpenses={this.state.projectData.external_expenses}
                              externalTotalCost={this.state.externalTotalCost}
                              projectId={this.state.projectData.id}
                              syncExternalExpenses={this.syncExternalExpenses}
                            />
                            }
                        </div>
                        }
                        <div className="project-details-row">
                            {this.state.projectData.id && !this.state.isLimitedPm &&
                                <TimesheetEntries
                                    resources={this.state.resources}
                                    projectId={this.state.projectData.id}
                                    projectMjn={this.state.projectData.mjn}
                                    projectStatus={this.state.projectData.status_id}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
ProjectDetails.contextType = AppContext;
export default compose(withRouter, withCookies)(ProjectDetails);
