import React, { Component } from 'react';
import Header from "../../components/header/Header";
import Sidebar from "../../components/sidebar/Sidebar";
import Paginate from "../../components/paginate/Paginate";
import AddUser from "../../components/addUser/AddUser";
import TableRow from "../../components/tableRow/TableRow";
import Preloader from "../../components/preloader/Preloader";
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from "prop-types";
import axios from 'axios';
import AppContext from "../../context/index";

class Users extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            usersList: [],
            locations: [],
            activityTypes: [],
            loading: false,
            page: 1,
            paginationKey: 0, //used to reset pagination when status changed
            totalResults: 0,
            resultsPerPage: 10,
            searchString: '',
            showAddUser: false,
            editUserId: '',
            editUserName: '',
            editUserEmail: '',
            editUserPhone: '',
            isLimitedPm: 0,
            editUserLocationId: 1,
            editUserActivityTypes: [],
            editUserProjectCategories: [],
            roleNames: {
                2: 'Infodesign PM',
                3: 'Hogarth PM',
                4: 'Developer',
                6: 'IT',
            }
        }

        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
        this.getUsers();
        this.getLocations();
        this.getActivityTypes();
        this.getProjectCategories();
    }

    openModal = (event, clearData) => {
        if (clearData) {
            this.setState({
                editUserId: '',
                editUserName: '',
                editUserEmail: '',
                editUserPhone: '',
                editUserLocationId: 1,
                isLimitedPm: 0,
                editUserActivityTypes: [],
                editUserProjectCategories: []
            })
        }
        this.setState({
            showAddUser: true
        });
    }

    closeModal = event => {
        this.setState({
            showAddUser: false
        });
    }

    pageChanged = pageNo => {
        this.setState(
            { page: pageNo },
            () => {
                this.getUsers();
            }
        );
    }

    searchUsersByName = event => {
        let searchStr = event.target.value;

        this.setState({
            searchString: searchStr
        }, () => {
            this.getUsers();
        });

    }

    getUsers = () => {
        const { cookies } = this.props;

        this.setState({
            loading: true
        })

        axios.get(process.env.REACT_APP_API_URL + 'users-by-role/' + this.props.roleId, {
            params: {
                page: this.state.page,
                resPerPage: this.state.resultsPerPage,
                searchString: this.state.searchString
            },
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.setState({
                usersList: res.data.data.users,
                totalResults: res.data.data.totalResults,
                loading: false
            });
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                })
            }
        })
    }

    editUser = (userId) => {
        const { cookies } = this.props;

        this.setState({
            loading: true
        })

        axios.get(process.env.REACT_APP_API_URL + 'user/' + userId, {
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            res = res.data.data;
            this.setState({
                loading: false,
                editUserId: res.id,
                editUserName: res.name,
                editUserEmail: res.email,
                editUserPhone: res.phone,
                editUserLocationId: res.location_id,
                isLimitedPm: res.is_limited_pm,
                editUserActivityTypes: res.UserActivities,
                editUserProjectCategories: res.UserProjectCategories,
                showAddUser: true
            })
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                })
            }
        })
    }

    addTeamLeadRole = (userId) => {
        const { cookies } = this.props;
        this.setState({
            loading: true
        })

        axios({
            method: 'PUT',
            url: process.env.REACT_APP_API_URL + 'users/' + userId,
            data: {
                is_teamlead: 1
            },
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.getUsers();
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                })
            }
        })
    }

    revokeTeamLeadRole = (userId) => {
        const { cookies } = this.props;
        this.setState({
            loading: true
        })

        axios({
            method: 'PUT',
            url: process.env.REACT_APP_API_URL + 'users/' + userId,
            data: {
                is_teamlead: 0
            },
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.getUsers();
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                })
            }
        })
    }

    saveUser = (userData) => {
        const { cookies } = this.props;

        this.setState({
            loading: true
        })

        //if we have a user id, it means we should update. so, the method for our request it's PUT. else, it will be a simple POST
        let requestMethod = "POST";
        if (userData.userId) {
            requestMethod = "PUT";
        }

        axios({
            method: requestMethod,
            url: process.env.REACT_APP_API_URL + 'users' + (userData.userId ? ('/' + userData.userId) : ''),
            data: {
                name: userData.userName,
                email: userData.userEmail,
                phone: userData.userPhone,
                location_id: userData.userLocationId,
                is_limited_pm: userData.isLimitedPm
            },
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            let userId = res.data.data.id;

            //save user's activities
            if (this.state.editUserActivityTypes || this.state.editUserProjectCategories) {
                axios({
                    method: "POST",
                    url: process.env.REACT_APP_API_URL + 'user-activity-types',
                    data: {
                        user_id: userId,
                        user_activities: this.state.editUserActivityTypes,
                        user_project_categories: this.state.editUserProjectCategories
                    },
                    headers: {
                        'Authorization': 'Bearer ' + cookies.get('authToken')
                    }
                })
                .then(res => {
                    console.log(res);
                })
            }

            if (requestMethod === 'POST'){
                //this user is a new one => save user's role
                axios({
                    method: "POST",
                    url: process.env.REACT_APP_API_URL + 'role',
                    data: {
                        user_id: userId,
                        role_id: userData.roleId
                    },
                    headers: {
                        'Authorization': 'Bearer ' + cookies.get('authToken')
                    }
                })
                .then(res => {
                    this.getUsers();
                    this.setState({
                        loading: false,
                        showAddUser: false
                    })
                })
                .catch(err => {
                    if(err.response.status === 401){
                        //redirect to login
                        this.context.redirectToLogin();
                    } else {
                        this.setState({
                            loading: false,
                            showAddUser: false
                        })
                    }
                })
            }
            else{
                //just load the users list again
                this.getUsers();
                this.setState({
                    loading: false,
                    showAddUser: false
                })
            }
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false,
                    showAddUser: false
                })
            }
        })
    }

    deleteUser = async (userId) => {
        const { cookies } = this.props;

        this.setState({
            loading: true
        })

        axios.delete(process.env.REACT_APP_API_URL + 'users/' + userId, {
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.getUsers();
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            } else {
                this.setState({
                    loading: false
                })
            }
        })
    }

    getLocations = () => {
        const { cookies } = this.props;
        axios.get(process.env.REACT_APP_API_URL + 'locations', {
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.setState({
                locations: res.data.data
            })
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            }
        })
    }
    getProjectCategories = () => {
        const { cookies } = this.props;
        axios.get(process.env.REACT_APP_API_URL + 'project-categories', {
            params: {
                page: 0,
                resPerPage: 1000
            },
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
          .then(res => {
              this.setState({
                  projectCategories: res.data.data.paginatedProjectCategories
              })
          })
          .catch(err => {
              if(err.response.status === 401){
                  //redirect to login
                  this.context.redirectToLogin();
              }
          })
    }
    getActivityTypes = () => {
        const { cookies } = this.props;
        axios.get(process.env.REACT_APP_API_URL + 'activity-types', {
            params: {
                page: 0,
                resPerPage: 1000
            },
            headers: {
                'Authorization': 'Bearer ' + cookies.get('authToken')
            }
        })
        .then(res => {
            this.setState({
                activityTypes: res.data.data.paginatedActivityTypes,
            })
        })
        .catch(err => {
            if(err.response.status === 401){
                //redirect to login
                this.context.redirectToLogin();
            }
        })
    }

    removeAT = (atId) => {
        let found = -1;
        let auxEditUserATs = this.state.editUserActivityTypes;
        for(let i = 0; i < auxEditUserATs.length; i++){
            if(auxEditUserATs[i].id == atId){
                found = i;
            }
        }
        if(found != -1) {
            auxEditUserATs.splice(found, 1);
            this.setState({
                editUserActivityTypes: auxEditUserATs
            });
        }
    }

    addAT = (atId) => {
        let found = -1;
        let auxEditUserATs = this.state.editUserActivityTypes;
        for(let i = 0; i < auxEditUserATs.length; i++){
            if(auxEditUserATs[i].id == atId){
                found = i;
            }
        }
        if(found == -1) {
            let atIndex = -1;
            for(let i = 0; i < this.state.activityTypes.length; i++){
                if(this.state.activityTypes[i].id == atId){
                    atIndex = i;
                }
            }
            auxEditUserATs.push(this.state.activityTypes[atIndex]);
            this.setState({
                editUserActivityTypes: auxEditUserATs
            });
        }
    }

    removePCat = (catId) => {
        let found = -1;
        let auxEditUserPCats = this.state.editUserProjectCategories;
        for(let i = 0; i < auxEditUserPCats.length; i++){
            if(auxEditUserPCats[i].id == catId){
                found = i;
            }
        }
        if(found != -1) {
            auxEditUserPCats.splice(found, 1);
            this.setState({
                editUserProjectCategories: auxEditUserPCats
            });
        }
    }

    addPCat = (catId) => {
        let found = -1;
        let auxEditUserPCats = this.state.editUserProjectCategories;
        for (let i = 0; i < auxEditUserPCats.length; i++) {
            if (auxEditUserPCats[i].id == catId) {
                found = i;
            }
        }
        if(found == -1) {
            let catIndex = -1;
            for (let i = 0; i < this.state.projectCategories.length; i++) {
                if(this.state.projectCategories[i].id == catId){
                    catIndex = i;
                }
            }
            auxEditUserPCats.push(this.state.projectCategories[catIndex]);
            this.setState({
                editUserProjectCategories: auxEditUserPCats
            });
        }
    }

    render() {
        return (
            <div className="page--with-header-sidebar clearfix">
                {this.state.loading && <Preloader />}
                <Sidebar />
                <div className="page--header-and-content">
                    <Header />
                    <div className="UsersPage">

                        <div className="TableHead">
                            <div className="TableHead--options">
                                <div className="row">
                                    <div className="th-searchbar">
                                        <i className="la la-search"></i>
                                        <input type="text" onChange={this.searchUsersByName} placeholder='Search users by name' />
                                    </div>
                                </div>
                            </div>

                            <a href="#" onClick={(e) => { this.openModal(e, true) }} className="btn-new-container">
                                <i className="la la-plus-circle"></i>
                                Add { this.state.roleNames[this.props.roleId] }
                                <AddUser
                                    userId={this.state.editUserId}
                                    userName={this.state.editUserName}
                                    userEmail={this.state.editUserEmail}
                                    userPhone={this.state.editUserPhone}
                                    roleId={this.props.roleId}
                                    locations={this.state.locations}
                                    isLimitedPm={this.state.isLimitedPm}
                                    activityTypes={this.state.activityTypes}
                                    projectCategories={this.state.projectCategories}
                                    userLocationId={this.state.editUserLocationId}
                                    editUserActivityTypes={this.state.editUserActivityTypes}
                                    editUserProjectCategories={this.state.editUserProjectCategories}
                                    removeAT={this.removeAT}
                                    addAT={this.addAT}
                                    removePCat={this.removePCat}
                                    addPCat={this.addPCat}
                                    saveUser={this.saveUser}
                                    closeModal={this.closeModal}
                                    isHogarthPm={this.props.roleId == 3}
                                    showPopup={this.state.showAddUser}
                                />
                            </a>

                        </div>

                        <div className="TableMainContainer">
                            {
                                this.state.usersList.map((item, index) => {
                                    return (
                                        (this.props.roleId == 2 || this.props.roleId == 3 || this.props.roleId == 6) ?
                                            <TableRow
                                                key={'row-' + index}
                                                tableCols={
                                                    [
                                                        {
                                                            value: item.name,
                                                            label: 'Name',
                                                            mobileFullWidth: true,
                                                            customCssClass: 'row-head-mobile'
                                                        },
                                                        {
                                                            value: item.email,
                                                            label: 'Email'
                                                        },
                                                        {
                                                            value: item.phone,
                                                            label: 'Phone'
                                                        },
                                                        {
                                                            value: !item.disabled,
                                                            label: 'Status',
                                                            showAsCheckbox: true
                                                        },
                                                        {
                                                            ddOptions: [
                                                                {
                                                                    value: 'Edit this user',
                                                                    action: {
                                                                        name: 'editUser',
                                                                        params: item.id
                                                                    }
                                                                },
                                                                {
                                                                    value: item.disabled ? 'Activate this user' : 'Inactivate this user',
                                                                    action: {
                                                                        name: 'deleteUser',
                                                                        params: item.id
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                                editUser={this.editUser}
                                                deleteUser={this.deleteUser}
                                            />
                                        :
                                        <TableRow
                                            key={'row-' + index}
                                            tableCols={
                                                [
                                                    {
                                                        value: item.name,
                                                        label: 'Name',
                                                        mobileFullWidth: true,
                                                        customCssClass: 'row-head-mobile'
                                                    },
                                                    {
                                                        value: item.UserActivities.map(activity => { return (' ' + activity.name) }),
                                                        label: 'Activity types'
                                                    },
                                                    {
                                                        value: item.location,
                                                        label: 'Location'
                                                    },
                                                    {
                                                        value: item.email,
                                                        label: 'Email'
                                                    },
                                                    {
                                                        value: item.phone,
                                                        label: 'Phone'
                                                    },
                                                    {
                                                        value: !item.disabled,
                                                        label: 'Status',
                                                        showAsCheckbox: true
                                                    },
                                                    {
                                                        value: item.is_teamlead,
                                                        label: 'Team lead',
                                                        showAsCheckbox: true
                                                    },
                                                    {
                                                        ddOptions: [
                                                            {
                                                                value: 'Edit this user',
                                                                action: {
                                                                    name: 'editUser',
                                                                    params: item.id
                                                                }
                                                            },
                                                            {
                                                                value: item.is_teamlead ? 'Revoke team lead role' : 'Add team lead role',
                                                                action: {
                                                                    name: item.is_teamlead ? 'revokeTeamLeadRole' : 'addTeamLeadRole',
                                                                    params: item.id
                                                                }
                                                            },
                                                            {
                                                                value: item.disabled ? 'Activate this user' : 'Inactivate this user',
                                                                action: {
                                                                    name: 'deleteUser',
                                                                    params: item.id
                                                                }
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                            editUser={this.editUser}
                                            deleteUser={this.deleteUser}
                                            addTeamLeadRole={this.addTeamLeadRole}
                                            revokeTeamLeadRole={this.revokeTeamLeadRole}
                                        />
                                    )
                                })
                            }
                        </div>
                        <Paginate key={this.state.paginationKey} triggerPageChange={this.pageChanged} pagesCounter={Math.ceil(this.state.totalResults / this.state.resultsPerPage)} />
                    </div>
                </div>
            </div>
        );
    }
}
Users.contextType = AppContext;
export default withCookies(Users);
